.nav {
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.nav-links {
  display: flex;
  justify-content: space-evenly;
  flex-flow: row;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  width: 50%;
  background-color: black;
}

.nav-link {
  color: rgb(226, 193, 4);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  margin: 1px;
  border-radius: 0.375rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
}

.nav-link:hover {
  background: #303947;
  color: white;
}

.hamburger {
  display: none;
  color: gold;
  padding: 20px;
  border-radius: 0.25rem;
  margin: 0.75rem;
  padding: 10px;
}

@media (max-width: 1280px) {
  .nav-links {
    width: 66%;
  }
}

@media (max-width: 1024px) {
  .hamburger {
    display: flex;
  }

  .hamburger:hover {
    background-color: #303947;
  }

  .nav-links {
    position: absolute;
    top: 84px;
    right: 0px;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    flex-flow: column;
    width: auto;
  }

  .nav-links-enter {
    transform: translateX(100%);
  }
  .nav-links-enter-active {
    transform: translateX(0);
    transition: transform 0.3s linear;
  }
  .nav-links-exit {
    transform: translateX(0);
  }
  .nav-links-exit-active {
    transform: translateX(100%);
    transition: transform 0.3s linear;
  }
}

@media (max-width: 290px) {
  .logo {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .nav-links {
    width: 100vw;
  }
}
