.about-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;
  padding: 10vh;
  text-align: center;
}

.about-details {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 1vh 0 0 0;
}

.face {
  height: 24rem;
  border-radius: 9999px;
  box-shadow: 0px 0px 100px grey;
}

.about-blurb {
  display: flex;
  flex-flow: column nowrap;
  padding: 5rem;
  width: 50%;
}

@media only screen and (max-width: 1200px) {
  .about-container {
    padding: 10vh 0 10vh 0;
  }
}

@media only screen and (max-width: 1024px) {
  .about-details {
    padding: 0;
  }
  .about-blurb {
    padding: 1vh 0 1vh 0;
  }
}

@media only screen and (max-width: 500px) {
  .about-blurb {
    padding: 2vh 0 0 0;
    width: 80vw;
  }
  .face {
    height: auto;
    width: 80vw;
  }
}
