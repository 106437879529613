.dev-all {
  background-color: black;
  height: 100%;
  width: 100%;
  padding: 10vh 0 0 0;
}

.dev-head {
  margin: 0 20vw 0 20vw;
  padding: 2vh;
  text-align: center;
  color: whitesmoke;
  font-size: 3rem;
}

.dev-portfolio {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  padding: 8vh 10vw 10vh 10vw;
}

@media (max-width: 500px) {
  .dev-head {
    font-size: 2rem;
  }
  .dev-portfolio {
    padding-top: 0vh;
  }
}
