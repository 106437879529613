.selected-projects-all {
  padding: 5vh 1vw 10vh 1vw;
  background-color: black;
}

.selected-projects-head {
  text-align: center;
  font-size: 3rem;
  color: white;
}

.selected-projects {
  display: flex;
  flex-flow: row nowrap;
}

.selected-projects > div {
  min-height: 90%;
  max-height: 100%;
}

@media (max-width: 1024px) {
  .selected-projects {
    flex-flow: row wrap;
    justify-content: center;
  }
}

/* @media (max-width: 800px) {
  .selected-projects {
    display: grid;
    grid-template-areas: "1 2";
    align-items: center;
    justify-content: center;
    row-gap: 2vh;
  }
} */

@media (max-width: 500px) {
  .selected-projects {
    display: flex;
    flex-flow: column wrap;
    width: 100vw;
  }
}
