.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
}

.footer-text {
  font-size: 0.8rem;
}
