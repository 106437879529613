.main-home-photo {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.me {
  margin: auto;
  background-color: rgba(0, 0, 0, 0.749);
  border-radius: 20px;
  padding: 2vw;
}

.name {
  font-size: 5rem;
  color: white;
}

.blurb {
  color: rgb(161, 160, 160);
  font-size: 3rem;
}

.read-more-link {
  color: rgb(226, 193, 4);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}

@media (max-width: 500px) {
  .name {
    font-size: 4rem;
  }
  .blurb {
    font-size: 2rem;
    padding: 2vw;
  }
  .me {
    padding: 3vw;
    width: 100vw;
    margin: auto 1vw auto 1vw;
    text-align: center;
  }
}

@media (min-height: 800px) {
  .me {
    max-height: 40vh;
  }
}
