.logo {
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin: 0.75rem;
  border-radius: 0.25rem;
  padding: 10px;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: bold;
}

.logo:hover {
  background-color: #303947;
}
