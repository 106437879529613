.contact-page-container {
  display: flex;
  flex-flow: column nowrap;
  /* grid-template-areas: "head head" "blurb form"; */
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 12vh 0 20vh 0;
  text-align: center;
  height: 100%;
  color: white;
  /* margin-bottom: 10vh; */
}

.contact-page-header {
  /* margin-top: 14vh; */
  font-size: 3rem;
}

.contact-page-blurb {
  max-width: 100%;
  margin: 0 2vw 0 2vw;
}

.contact-page-email {
  margin-top: 2vh;
  color: gold;
  text-align: center;
  margin-bottom: 2vh;
}

.contact-form {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  color: black;
  /* margin-bottom: 10vh; */
}

.contact-form-label {
  display: none;
}

.contact-form-input {
  margin: 1vh 0 1vh 0;
  width: 100%;
  min-height: 7vh;
  max-height: 20vh;
  padding: 10px;
  box-shadow: inset 0px 0px 5px black;
}

.submit-button {
  color: rgb(226, 193, 4);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  margin: 1px;
  border-radius: 0.375rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  border: 1px solid white;
}

.submit-button:hover {
  background: #303947;
  color: white;
  border-color: gold;
}

.sent {
  margin: 2vh 0 2vh 0;
}

@media only screen and (max-width: 1200px) {
  /* .contact-page-container {
    padding: 10vh 0 20vh 0;
  }
  .contact-page-blurb {
    padding: 2vh 0 0 0;
    width: 80vw;
  } */
}

/* @media only screen and (max-width: 500px) {
  .about-face {
    height: auto;
    width: 80vw;
  }
} */
